import request from '@/utils/request'

// 查询剧本镇店价格配置列表
export function listTreasureConfig(query) {
  return request({
    url: '/script/treasure/config/list',
    method: 'get',
    params: query
  })
}

// 查询剧本镇店价格配置详细
export function getTreasureConfig(id) {
  return request({
    url: '/script/treasure/config/' + id,
    method: 'get'
  })
}

// 新增剧本镇店价格配置
export function addTreasureConfig(data) {
  return request({
    url: '/script/treasure/config',
    method: 'post',
    data: data
  })
}

// 修改剧本镇店价格配置
export function updateTreasureConfig(data) {
  return request({
    url: '/script/treasure/config',
    method: 'put',
    data: data
  })
}

// 删除剧本镇店价格配置
export function delTreasureConfig(id) {
  return request({
    url: '/script/treasure/config/' + id,
    method: 'delete'
  })
}
